html,
body {
  margin: 0;
  height: 100%;
}

form {
  flex: 1;
}

#root {
  height: 100%;
  display: flex;
}